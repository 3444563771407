import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Home from '../components/Home';
import UtenteButton from '../components/UtenteButton';
import imgSfondo from '../img/sfondo_home.jpeg';

const Tab1: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Bleisure - Lusso à-porter</IonTitle>
          <IonButtons slot="end">
            <UtenteButton triggerId={'trigger-button'+'_home'}/>
          </IonButtons>         
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Bleisure à-porter</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{height:'100%',width:'100%',backgroundSize: 'contain',background: 'url('+imgSfondo+')'}}>
          <Home />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
