import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendarOutline, giftOutline, homeOutline, podiumOutline, restaurantOutline } from 'ionicons/icons';
import HomeTab from './pages/HomeTab';
import RistoranteTab from './pages/RistoranteTab';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import SalaPage from './pages/SalaPage';
import PrenotaSalaPage from './pages/PrenotaSalaPage';
import SaleTab from './pages/SaleTab';
import EventiTab from './pages/EventiTab';
import AporterTab from './pages/AporterTab';
import AporterCategoria from './components/AporterCategoria';
import AporterCategoriaPage from './pages/AporterCategoriaPage';

// const getConfig:any = () => {
//   console.log('Get config')
//   return {
//     backButtonText: false,    
//     mode: "md",
//     navAnimation: iosTransitionAnimation    
//   };
// }

setupIonicReact({
    //navAnimation: iosTransitionAnimation,
    mode: "md",
  });

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet id="main">

          <Route exact path="/home">
            <HomeTab />
          </Route>
           
          <Route exact path="/ristorante" >
            <RistoranteTab />
          </Route>

          {/* <Route exact path="/sale" component={SaleTab} /> */}

           <Route exact path="/sale" >
             <SaleTab />
           </Route>

          <Route path="/sale/:id"  component={SalaPage} />
          {/* <Route path="/prenotaSala" component={PrenotaSalaPage} /> */}
          <Route exact path="/prenotaSala" component={(props:any) => <PrenotaSalaPage {...props}  />} />

          <Route path="/eventi">
            <EventiTab />
          </Route>

          <Route path="/aporter">
            <AporterTab />
          </Route>

          <Route path="/categoria/:categoria"  component={AporterCategoriaPage} />
          
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home" >
            <IonIcon icon={homeOutline} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="ristorante" href="/ristorante">
            <IonIcon icon={restaurantOutline} />
            <IonLabel>Ristorante</IonLabel>
          </IonTabButton>
          <IonTabButton tab="sale" href="/sale">
            <IonIcon icon={podiumOutline} />
            <IonLabel>Sale Meeting</IonLabel>
          </IonTabButton>
          <IonTabButton tab="eventi" href="/eventi">
            <IonIcon icon={calendarOutline} />
            <IonLabel>Eventi</IonLabel>
          </IonTabButton>
          <IonTabButton tab="aporter" href="/aporter">
            <IonIcon icon={giftOutline} />
            <IonLabel>À-porter</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
