import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonSkeletonText, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import splitArray from '../utils/splitArray';
import { eventiFake } from '../utils/fakeData';

// const categoria = {id:'',nome:'',img:'https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg'};
// const categorie: { id: number; nome: string; img: string; }[] = [];
// const creaArray = function () {
//     for (let index = 0; index < 10; index++) {
//         categorie.push({ ...categoria, id: index , nome:'CATEGORIA A'+index});
//     }
// }()

const listaSplitatta = splitArray(eventiFake,2)
console.table(eventiFake);

const Eventi: React.FC = () => {   

    
    return (
            <IonGrid>
                {
                    listaSplitatta.map((cat,index) => {
                        return <IonRow key={index}>
                            {
                                cat.map((x:any,index:number) => { 
                                    return <IonCol size='6' className='' key={index}>
                                        <IonCard className='ion-no-margin'>
                                            <IonItem>                                                
                                                <IonLabel>{x.nome}</IonLabel>
                                            </IonItem>

                                            <IonCardContent>
                                                <img src={x.img} />
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                })
                            }
                        </IonRow>
                    })
                }
            </IonGrid>
    );
};

export default Eventi;