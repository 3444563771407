import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from 'react-router';

import AporterCategoria from "../components/AporterCategoria";
import Carrello from "../components/Carrello";
import UtenteButton from "../components/UtenteButton";
import { categorie, sottoCategorie } from '../utils/fakeData';

// const sottoCategorie = ['Sotto 1','Sotto 2','Sotto 3','Sotto 4','Sotto 5','Sotto 6','Sotto 7','Sotto 8','Sotto 9']

const AporterCategoriaPage: React.FC = (props: any) => {

    const { categoria } = useParams<any>()

    const findCategoria = (nomeCategoria: string) => {

        let newObj: any
        sottoCategorie.map((x) => {
            if (x.categoria === nomeCategoria) newObj = x
        })
        return newObj
    }

    const findCategoriaProdotti = (nomeCategoria: string) => {

        let newLista: any[] = []
        findCategoria(categoria).lista.map((x: any) => {
            if ((x.categoria && x.categoria.indexOf(nomeCategoria) !== -1) || nomeCategoria === 'Tutti') {
                newLista.push(x);
            }
        })
        return newLista
    }

    const [listaProdotti, setListaProdotti] = useState<any>([])
    const [listaSottocategorie, setListaSottoCategorie] = useState<any>([])
    const [sottoCategoriaScelta, setSottoCategoriaScelta] = useState<string>('Tutti')

    useEffect(() => {
        let newCategoria = findCategoria(categoria)
        setListaProdotti(newCategoria.lista)
        setListaSottoCategorie(newCategoria.sottocategorie)
        setSottoCategoriaScelta('Tutti')
    }, [categoria])

    const handleCambioSottoCategoria = (sottoCategoriaScelta: string) => {
        setListaProdotti(findCategoriaProdotti(sottoCategoriaScelta))
        setSottoCategoriaScelta(sottoCategoriaScelta)
    }

    return (
        <IonPage id="APorterPage">
            <IonHeader >
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/sale" />
                    </IonButtons>
                    <IonTitle size="large">{categoria}</IonTitle>
                    <IonButtons slot="end">
                        <Carrello />
                        <UtenteButton triggerId={'trigger-button'+'_aporter_categoria'}/>
                    </IonButtons>
                </IonToolbar>
                {listaSottocategorie && listaSottocategorie.length > 1 && <IonToolbar> 
                    <IonSegment scrollable value={sottoCategoriaScelta} color="medium">
                        {
                            listaSottocategorie.map((x: any, index: number) => (
                                <IonSegmentButton value={x} key={index} onClick={() => handleCambioSottoCategoria(x)}>
                                    <IonLabel>{x}</IonLabel>
                                </IonSegmentButton>
                            ))
                        }
                    </IonSegment>
                </IonToolbar>}
            </IonHeader>
            <IonContent className="sala">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Cat</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <AporterCategoria listaProdotti={listaProdotti} />
            </IonContent>
        </IonPage>


    );
}

export default AporterCategoriaPage