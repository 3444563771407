import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Ristorante from '../components/Ristorante';
import UtenteButton from '../components/UtenteButton';
import './RistoranteTab.css';



const RistoranteTab: React.FC = () => {

  const location = useLocation();
  const [entrato,setEntrato] = useState<any>(false); 

  // All'ingresso in pagina
  useEffect(() => {
    console.log('entrato',entrato, location);       
    setEntrato(location.pathname.substring(0, 11) == '/ristorante' ? true:false)
  }, [location.key]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ristorante</IonTitle>
          <IonButtons slot="end">
            <UtenteButton triggerId={'trigger-button'+'_ristorante'}/>
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Ristorante</IonTitle>
          </IonToolbar>
        </IonHeader>
        {entrato && <Ristorante  />}
      </IonContent>
    </IonPage>
  );
};

export default RistoranteTab;
