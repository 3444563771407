import { useState } from 'react'
import {costanti} from './costant'
import localforage from 'localforage' 

const useLocalForage = () => {

    const get = (chiave:string) => {
        console.log('GET LOCALSTORAGE',localforage.getItem(chiave))
        return localforage.getItem(chiave);
    }

    const set = (chiave:string,data:any) => {
        console.log('SET LOCALFORAGE',data)
        localforage.setItem('datiUtente',data)
    }

    return { set, get };
};
export default useLocalForage;