import { IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonText, IonToolbar } from "@ionic/react";
import * as icon from "ionicons/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./Sala.css";

interface datiSalaTipo {
    datiSala : object
}

export const Sala : React.FC<datiSalaTipo> = (props:datiSalaTipo) => {

    // const { dismiss, category = false } = props;
    //const product = { image: '', title: '', price: '' };
    const [dati,setDati] = useState<any>({});
    const [immagine,setImmagine] = useState<any>(dati.image);
    //const contentRef = useRef(null);

    useEffect(() => {
        console.log('USE TEST SALA')
        setDati(props);
        // setImmagine(props?.datiSala?.image);
    }, [])
    
    return (
        <>
            <IonCard style={{ transition: 'background 0.4s linear', backgroundImage: `url('${dati.image}')` }}>
                <IonCardHeader>
                    <IonCardTitle>{dati?.descBreve}</IonCardTitle>
                    <IonCardSubtitle>{dati?.capacità} Persone | A partire da {dati?.prezzoOrario} € / Ora</IonCardSubtitle>
                </IonCardHeader>
            </IonCard>

            <IonList>

                <IonItem >
                    <IonLabel className="ion-text-wrap">
                        <h1>Descrizione</h1>
                        <p>{dati.descrizione}</p>
                    </IonLabel>
                </IonItem>

                {/* <h2>Descrizione</h2>
                <IonText>{dati.descrizione}</IonText> */}

                <IonItem>
                    <IonLabel>
                        <h1>Servizi</h1>
                    </IonLabel>
                </IonItem>
                
                {
                    dati?.servizi?.map((s:any,index:number) => {
                        let icona = s.icona as keyof typeof icon;
                        return <IonItem key={s.id}>
                            <IonIcon slot="start" icon={icon[icona]}></IonIcon>
                            <IonLabel>{s.servizio}</IonLabel>
                        </IonItem>
                    })
                }

                <IonItem>
                    <IonLabel>
                        <h1>Listino</h1>
                    </IonLabel>
                </IonItem>
                {
                    dati?.listino?.map((l:any,index:number) => {
                        return <IonItem key={index}>
                            <IonIcon slot="start" icon={icon['time']}></IonIcon>
                            <IonLabel>Ore: {l.ore} - Prezzo: {l.prezzo},00 €</IonLabel>
                        </IonItem>
                    })
                }
            </IonList>

            <IonFooter collapse="fade" style={{position:'sticky',bottom:0}}>
                <IonToolbar>
                    {/* <IonButton expand="block" color="success" size="default" shape="round" routerLink={'/prenotaSala'} routerDirection="forward" >Prenota ORA</IonButton> */}
                    <Link to={{ pathname:"/prenotaSala",state:dati }} style={{textDecoration:'none'}}>
                        <IonButton expand="block" color="primary" size="default" disabled={dati.nome ? false:true}>Prenota ORA</IonButton>
                    </Link>
                </IonToolbar>
            </IonFooter>

        </>
    );
}