import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Aporter from '../components/Aporter';
import Carrello from '../components/Carrello';
import UtenteButton from '../components/UtenteButton';

const AporterTab: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>À-porter</IonTitle>
          <IonButtons slot="end">
            <Carrello /> 
            <UtenteButton triggerId={'trigger-button'+'_aporter'}/>
            
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">À-porter</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Aporter />
      </IonContent>
    </IonPage>
  );
};

export default AporterTab;
