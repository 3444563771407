import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Sale from '../components/Sale';
import UtenteButton from '../components/UtenteButton';
import './SaleTab.css';

const Tab2: React.FC = () => {

  const location = useLocation();
  const [entrato,setEntrato] = useState<any>(false); 

  // All'ingresso in pagina
  // useEffect(() => {
  //   console.log('USE TEST INGRESSO',location.key)
      //setEntrato(location.pathname.substring(0, 5) == '/sale' || location.pathname == '/prenotaSala' ? true:false)
    
  // }, [location.key]);

  useIonViewWillEnter(() => {
      console.log('USE ION WILL ENTER')
      setEntrato(true)
  });

  useIonViewWillLeave(()=>{
    console.log('USE ION WILL EXIT')
    setEntrato(false)
  })

  return (
    <IonPage id="Tab2">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sale Riunini / Conferenze</IonTitle>
          <IonButtons slot="end">
            <UtenteButton triggerId={'trigger-button'+'_sale'}/>
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      <IonContent className="sale">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Sale Riunini / Conferenze</IonTitle>
          </IonToolbar>
        </IonHeader>
        {entrato && <Sale />}
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
