
const splitArray = (array:any, n:number) => {
  let [...arr] = array;
  let res = [];
  while (arr.length) {
    res.push(arr.splice(0, n));
  }
  return res;
}

export default splitArray

