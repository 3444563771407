import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Sala } from "../components/Sala";
import UtenteButton from "../components/UtenteButton";
import useApi from "../utils/api";

const SalaPage: React.FC = (props: any) => {

    //const dati = props?.location?.state?.data;

    const [dati, setDati] = useState<any>({});
    const { id } = useParams<any>();
    const [alertPopup] = useIonAlert();
    const { isLoading, isError, data: dataReturn, call } = useApi('GET', 'saleBleisure', id)

    useEffect(() => {

        call()
        //setDati(props.props);
    }, [id])

    useEffect(() => {
        if (dataReturn?.data?.sala) {
            setDati(dataReturn?.data.sala);
        }
    }, [dataReturn])

    // intercetto l'errore del server e lo mostro all'utente.
    useEffect(() => {
        if (isError) alertPopup('Errore nella comunicazione con il server')
    }, [isError])


    return (
        <IonPage id="SalaPage">
            <IonHeader >
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/sale" />
                    </IonButtons>                        
                    <IonTitle > {dati?.nome}</IonTitle>
                    <IonButtons slot="end">
                        <UtenteButton triggerId={'trigger-button' + '_sala'} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            
            <IonContent className="sala" fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Prova</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {dati.id && <Sala {...dati} />}
            </IonContent>
        </IonPage>


    );
}

export default SalaPage