import { useState } from 'react';
import axios, { Method } from 'axios';
import {costanti} from './costant'

// ISPIRAZIONE
// https://blog.openreplay.com/data-fetching-techniques-with-react

const useApi = (tipoChiamata:Method = 'GET', endpoint:string, id = null) => {   
    

    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    
    const resetDefaults = () => {
        setData({});
        setIsError(false);
        setIsLoading(true);
    }

    const call = (dati?:any) => {

        resetDefaults() 
        
        let requestUrl = 'https://' + costanti.URL_API + '/' + costanti.API_PREFIX + '/' + endpoint + '/' + (id ? id:'');        

        axios({
            method: tipoChiamata,
            headers: {
                [costanti.CHIAVE_API_HEADER] : costanti.CHIAVE_API_VALORE,
                'Content-Type': 'application/json'
            },
            url: requestUrl,
            data: dati // {success:false, error:false, warning:false, data:{}} {success:'Riuscita',error:'non sei autorizzato',warning:'Non hai inserito il parametro obbligatorio', data: {} | {}}
        })            
        .then((response) => {
            setData(response.data);
            setIsLoading(false);

        })
        .catch((error) => {
            setIsLoading(false);
            setIsError(true);
            console.log({error});
        })
    }

    return { isLoading, isError, data, call };
};
export default useApi;