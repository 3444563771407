import imgPiatto from '../img/piatto_default.png';

const enviroment = 'dev';

const inputsDefaultPrenotazioneSala = [
    {
        id: 12,
        name: "sala",
        type: "hidden",
        placeholder: "",
        label: "Sala",
        required: true,
        disabled: true,
        value: "",
        error: 'false'
    },
    {
        id: 1,
        name: "nome",
        type: "text",
        autocomplete: 'off',
        placeholder: "Nome",
        errorMessage:
            "Il nome deve essere tra i 3 e i 16 caratteri e non avere numeri o caratteri speciali!",
        label: "Nome",
        pattern: "^[A-Za-z ]{3,16}$",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 2,
        name: "cognome",
        type: "text",
        placeholder: "Cognome",
        errorMessage:
            "Il cognome deve essere tra i 3 e i 16 caratteri e non avere numeri o caratteri speciali!",
        label: "Cognome",
        pattern: "^[A-Za-z ]{3,16}$",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 3,
        name: "telefono",
        type: "tel",
        placeholder: "Telefono",
        errorMessage:
            "Inserisci un numero valido",
        label: "Telefono",
        pattern: "^[0-9]+$",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 4,
        name: "email",
        type: "email",
        placeholder: "Il tuo indirizzo E-Mail",
        label: "E-Mail",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 5,
        name: "azienda",
        type: "text",
        placeholder: "Ragione Sociale dell'azienda",
        errorMessage:
            "La ragione sociale deve essere tra i 3 e i 16 caratteri e non avere caratteri speciali!",
        label: "Azienda",
        pattern: "^[A-Za-z0-9 ]{3,16}$",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 6,
        name: "data",
        type: "date",
        placeholder: "Data",
        errorMessage:
            "Devi scegliere una data valida",
        label: "Data",
        pattern: "",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 7,
        name: "oraInizio",
        type: "time",
        placeholder: "Ora di inizio",
        errorMessage:
            "Devi scegliere un'ora valida",
        label: "Dalle Ore",
        pattern: "",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 8,
        name: "oraFine",
        type: "time",
        placeholder: "Ora di fine",
        errorMessage:
            "Devi scegliere un'ora valida",
        label: "Alle ore",
        pattern: "",
        required: true,
        value: '',
        error: 'false'
    },
    {
        id: 9,
        name: "numeroPersone",
        type: "number",
        placeholder: "Numero persone",
        errorMessage:
            "Devi inserire un numero valido",
        label: "Quante persone",
        pattern: "^[0-9]+$",
        required: true,
        max: 70,
        value: '',
        error: 'false'
    },
    {
        id: 10,
        name: "note",
        type: "textarea",
        placeholder: "Scrivi qui eventuali note a riguardo",
        label: "Note",
        value: '',
        error: 'false'
    },
]

const datiDefault = {

    dev: {
        URL_API: 'apidev.bleisure.space',
        API_PREFIX: 'api',
        CHIAVE_API_HEADER: 'segretissimo',
        CHIAVE_API_VALORE: 'gmyusj2j38cax',
        INPUT_DEFAULT_PRENOTAZIONE_SALA: inputsDefaultPrenotazioneSala,
        IMMAGINI_RISTORANTE: [
            'https://bleisure.space/wp-content/uploads/2022/03/ph-Lu-Magaro-35-1-scaled.jpg',
            'https://bleisure.space/wp-content/uploads/2022/03/IMG_0355-scaled.jpg',
            'https://bleisure.space/wp-content/uploads/2022/03/ph-Lu-Magaro-5-1-scaled.jpg'
        ],
        IMMAGINE_PIATTO_DEFAULT: imgPiatto,
        MENU_RISTORANTE: [{
            "tipo": "Bunburger",
            "lista": [{
                "img": "https://bleisure.space/immagini/portata-1.jpeg",
                "nome": "Burger di Fassona",
                "testo": "Burger di fassona piemontese con bacon croccante, pomodoro datterino, fonduta di parmiggiano e insalata",
                "orario": ["pranzo"]
            }]
        },
        {
            "tipo": "Antipasti",
            "lista": [{
                "img": "https://bleisure.space/immagini/portata-2.jpeg",
                "nome": "Pan Brioche, tartare di fassona e rafano",
                "testo": "",
                "orario": ["pranzo"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-3.jpeg",
                "nome": "Catalana di gamberi",
                "testo": "",
                "orario": ["pranzo"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-1.jpeg",
                "nome": "Ricciola",
                "testo": "Mosaico di Ricciola, fumetto montato e olio levistico",
                "orario": ["cena"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-2.jpeg",
                "nome": "Aspargo con riccio",
                "testo": "Aspargo bianco di Bassano del Grappa con riccio di mare e caffé",
                "orario": ["cena"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-3.jpeg",
                "nome": "Melanzana alla mediterranea",
                "testo": "",
                "orario": ["cena"]
            }
            ]
        },
        {
            "tipo": "Primi",
            "lista": [
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Orecchiette al ragù di pesce",
                    "testo": "",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-2.jpeg",
                    "nome": "Risotto prezzemolo e cozze",
                    "testo": "",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-3.jpeg",
                    "nome": "Spaghetto al ragù bianco di cortile",
                    "testo": "",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Risotto all'ortica",
                    "testo": "Risotto all'ortica di campo, limone bruciato e cozze",
                    "orario": ["cena"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-2.jpeg",
                    "nome": "Cannellone con anatra e coriandolo",
                    "testo": "Canellone di pasta all'uovo con anatra e coriandolo",
                    "orario": ["cena"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-3.jpeg",
                    "nome": "MescaFrancesca con cavolfiore",
                    "testo": "MescaFrancesca con cavolfiore, pane di grani antichi croccante al profumo di agrumi e prezzemolo",
                    "orario": ["cena"]
                }

            ]
        },
        {
            "tipo": "Secondi",
            "lista": [
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Tataki",
                    "testo": "Tataki di salmone con millefoglie di sedano rapa",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-2.jpeg",
                    "nome": "Medaglione",
                    "testo": "Medaglione di vitello con verdura scottata",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-3.jpeg",
                    "nome": "Millefoglie",
                    "testo": "Millefoglie di verdure di stagione",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Anguilla",
                    "testo": "Abuilla laccata con jus di vitello e asparagi e ghiaccio",
                    "orario": ["cena"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-2.jpeg",
                    "nome": "Pancia di maiale croccante",
                    "testo": "Pancia di maiale croccante, chutney di kiwi e packhoi",
                    "orario": ["cena"]
                }
            ]
        },
        {
            "tipo": "Insalate",
            "lista": [{
                "img": "https://bleisure.space/immagini/portata-3.jpeg",
                "nome": "Torno Salad",
                "testo": "Riso Venere, tonno, pomodorini gialli, feta, zucchine julienne",
                "orario": ["pranzo"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-1.jpeg",
                "nome": "Fish Salad",
                "testo": "Misticanza, trota salmonata, avocado, pomodorini al forno e yogurt al rafano",
                "orario": ["pranzo"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-2.jpeg",
                "nome": "La Diversa",
                "testo": "Misticanza, finocchi, arancia, noci",
                "orario": ["pranzo"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-3.jpeg",
                "nome": "Caesar Salad",
                "testo": "Iceberg, pollo, parmigiano, bacon, crostini di pane e salsa senape",
                "orario": ["pranzo"]
            }
            ]
        },
        {
            "tipo": "Evergreen",
            "lista": [{
                "img": "https://bleisure.space/immagini/portata-1.jpeg",
                "nome": "Riso al salto",
                "testo": "Riso al salto alla milanese",
                "orario": ["pranzo", "cena"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-2.jpeg",
                "nome": "Risotto alla milanese con Ossobuco",
                "testo": "",
                "orario": ["pranzo", "cena"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-3.jpeg",
                "nome": "Strozzapreti",
                "testo": "Strozzapreti al nero di seppia, caviale, salmone, gamberetti",
                "orario": ["pranzo", "cena"]
            },
            {
                "img": "https://bleisure.space/immagini/portata-1.jpeg",
                "nome": "La Cotoletta",
                "testo": "Carne di vitello impanata con patate al forno",
                "orario": ["pranzo", "cena"]
            }
            ]
        },
        {
            "tipo": "Frutta",
            "lista": [{
                "img": "https://bleisure.space/immagini/portata-2.jpeg",
                "nome": "Macedonia di frutta",
                "testo": "",
                "orario": ["pranzo"]
            }]
        },
        {
            "tipo": "Dolci",
            "lista": [
                {
                    "img": "https://bleisure.space/immagini/portata-3.jpeg",
                    "nome": "Cheesecake",
                    "testo": "",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Tiramisù",
                    "testo": "",
                    "orario": ["pranzo"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-2.jpeg",
                    "nome": "Tartelletta",
                    "testo": "Tatelletta al cacao composta di sedano rapa e pralinato di nocciola",
                    "orario": ["cena"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-3.jpeg",
                    "nome": "Stecco di gelato",
                    "testo": "Stecco di gelato alla banana cn fava tonka e meringa alla violetta",
                    "orario": ["cena"]
                },
                {
                    "img": "https://bleisure.space/immagini/portata-1.jpeg",
                    "nome": "Mou du noci",
                    "testo": "Mou du noci do peacan con gelato al rum e miele e spuma di cioccolato fondente",
                    "orario": ["cena"]
                }
            ]
        }
        ]
    },

    prod: {
        URL_API: '',
        API_PREFIX: 'api',
        CHIAVE_API_HEADER: '',
        CHIAVE_API_VALORE: '',
        INPUT_DEFAULT_PRENOTAZIONE_SALA: inputsDefaultPrenotazioneSala,
        IMMAGINI_RISTORANTE: [
            'https://bleisure.space/wp-content/uploads/2022/03/ph-Lu-Magaro-35-1-scaled.jpg',
            'https://bleisure.space/wp-content/uploads/2022/03/IMG_0355-scaled.jpg',
            'https://bleisure.space/wp-content/uploads/2022/03/ph-Lu-Magaro-5-1-scaled.jpg'
        ]
    }
}

const costanti = datiDefault[enviroment];

export { enviroment, costanti }