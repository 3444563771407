import { IonInput, IonItem, IonLabel, IonNote, IonText, IonTextarea } from "@ionic/react";
import { useState } from "react";
// import "./formInput.css";

const FormInput = (props:any) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

//   if(props.name == 'username') console.log('render',props);

  const handleFocus = (e:any) => {
    setFocused(true);
  };

  return ( 
    <>  
      {inputProps.type != 'hidden' && <IonItem>
          <IonLabel position="floating">{label}</IonLabel>
          {inputProps.type != 'textarea' && <IonInput
              {...inputProps}
              onIonChange={onChange}
              onIonBlur={handleFocus}           
              focused={focused.toString()}
          ></IonInput>}
          {inputProps.type == 'textarea' && <IonTextarea
              {...inputProps}
              onIonChange={onChange}
              onIonBlur={handleFocus}
              focused={focused.toString()}
          ></IonTextarea>}

          {props.error == 'true' && <IonNote color="danger">{errorMessage}</IonNote>}
      </IonItem>}
    </> 
  );
};

export default FormInput;