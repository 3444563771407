import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonSkeletonText, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApi from '../utils/api';
import './Sale.css';

// const listaSale = [
//     { nome: 'Sting', image: 'https://bleisure.space/wp-content/uploads/2021/09/IMG_0757-1-scaled.jpg', descBreve: 'test', capacità: 4, prezzoOrario: 50.00 },
//     { nome: 'Prince', image: 'https://bleisure.space/wp-content/uploads/2022/01/IMG_9961-min-scaled.jpg', descBreve: 'test', capacità: 10, prezzoOrario: 50.00 },
//     { nome: 'Zeppelin', image: 'https://bleisure.space/wp-content/uploads/2021/09/IMG_0749-scaled.jpg', descBreve: 'test', capacità: 2, prezzoOrario: 50.00 },
//     { nome: 'Altra', image: 'https://bleisure.space/wp-content/uploads/2021/09/IMG_0757-1-scaled.jpg', descBreve: 'test', capacità: 30, prezzoOrario: 50.00 },
// ]

const Sale: React.FC = () => {

    const { isLoading, isError, data: dataReturn, call } = useApi('GET', 'saleBleisure')
    const [listaSale, setListaSale] = useState<any>([])
    const [classeAnimazione, setClasseAnimazione] = useState('animati')
    const [alertPopup] = useIonAlert();

    useEffect(() => {
        call()        
    }, [])

    useEffect(() => {
        console.log(dataReturn);
        if (dataReturn?.data?.listaSale){
            setListaSale(dataReturn?.data?.listaSale)
            setTimeout(() => { setClasseAnimazione('') },3000)
        } 
    }, [dataReturn])

    // intercetto l'errore del server e lo mostro all'utente.
    useEffect(() => {
        if(isError) alertPopup('Errore nella comunicazione con il server')
    },[isError])

    return (
            <IonList lines="none" style={{backgroundColor:'var(--ion-color-step-200)'}}>
                <IonListHeader>
                    <IonLabel>Scegli la sala ideale per te e prenota</IonLabel>
                </IonListHeader>       

                {
                    !isLoading && listaSale.map((item: any, index:any) => (
                        <IonItem key={item.linkSala} shape="round" className={classeAnimazione} style={{width:"100%", "--animation-order": index } as any} routerLink={'/sale/'+item.linkSala}>
                            {/* <Link to={{ pathname: '/sala/' + item.linkSala, state: { data: item } }} style={{ textDecoration: 'none' }}> */}
                                <IonCard style={{borderRadius:'10px',width:'100%'}}>
                                    <img src={item.image} alt="" className='imgListaSale'/>
                                    <IonCardHeader>
                                        <IonCardSubtitle>Capienza: {item.capacità}</IonCardSubtitle>
                                        <IonCardTitle>{item.nome}</IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            {/* </Link> */}
                        </IonItem>
                    ))
                }
                {
                    isLoading && <IonItem shape="round">

                        <IonCard style={{width:"100%"}}>
                            <IonSkeletonText animated style={{ height: "300px", width:"100%" }}></IonSkeletonText>
                            <IonCardHeader>
                                <IonCardSubtitle><IonSkeletonText animated></IonSkeletonText></IonCardSubtitle>
                                <IonCardTitle><IonSkeletonText animated></IonSkeletonText></IonCardTitle>
                            </IonCardHeader>
                        </IonCard>

                        
                    </IonItem>
                }
            </IonList>
    );
};

export default Sale;