import React, { useState } from 'react';
import { IonSkeletonText } from '@ionic/react';
//import imageFallback from '../img/fall.jpeg';

const heightDefault = '240px';

const ImageSpecial: React.FC<{ sorgente: string, height?: any}> = (props) => {

    const [aspetta, setAspetta] = useState<any>(true);
    const [imgLoad, setImageLoad] = useState<any>(props.sorgente);

    return (
        <>  
            <div style={{display: 'block', margin: 0, padding: 0, height: props.height ? props.height : heightDefault,width:'100%'}}>
                {aspetta && <IonSkeletonText animated style={{ height: props.height ? props.height : heightDefault, width: '100%', margin: 0, padding: 0 }} />}
                <img onLoad={() => { setAspetta(false) }} onError={() => { /*setImageLoad(imageFallback)*/ }} src={imgLoad} alt="" style={{ height: aspetta ? '0px' : (props.height ? props.height : heightDefault), objectFit: 'cover', width: '100%', objectPosition: 'top', display: 'inline', opacity: aspetta ? '0' : '1', transition: 'opacity 0.2s linear' }} />
            </div>
        </>
    )
};

export default ImageSpecial