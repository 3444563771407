import { IonButton, IonItem, IonLabel, IonList, IonListHeader, IonNote, useIonAlert, useIonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FormInput from "./FormInput";
import useApi from '../utils/api';
import { costanti } from '../utils/costant';
import useLocalForage from '../utils/localforageSrv';


const FormPrenotazioneSala: React.FC<any> = ({datiSala}) => {

    const {isLoading, isError, data:dataReturn, call} = useApi('POST','prenotaSala')
    const [present, dismiss] = useIonLoading();
    const [alertPopup] = useIonAlert();
    const history = useHistory();
    const { set:salvaInLocale, get:prendiDaLocale } = useLocalForage();

    interface objForm {
        nome: '';
        cognome: '';
        telefono: '';
        email:'';
        azienda: '';
        data: '';
        oraInizio: '';
        oraFine:'';
        numeroPersone:'';
        sala:'';
        note:'';
        errore:'false';
        [key: string]: string;
    }

    const [values, setValues] = useState<objForm>({} as objForm);   
    const [inputs, setInputs] = useState<any>(costanti.INPUT_DEFAULT_PRENOTAZIONE_SALA);

    // Setta il form con i dati di default. Se ho i dati dell'utente li setto già nel form in modo che non debba reinseriri tutte le volte
    const setFormDefault = ()=>{
        if (datiSala.nome){
            prendiDaLocale('datiUtente').then((res:any)=>{
                setValues({ ...{} as objForm, ...{  ...res, sala: datiSala?.nome}})
            })            
        } 
    }     

    // intercetto i dati di ritorno e gli errori "formali"
    useEffect(() => {
        if(dataReturn?.success === 'Riuscita' && !isError) {
            let datiUtente = {nome: values.nome || '',cognome: values.cognome || '',telefono: values.telefono || '',azienda: values.azienda || '',email: values.email || ''}
            salvaInLocale('datiUtente',datiUtente)
            alertPopup('Richiesta effettuata, arriverà una mail il prima possibile!')
            setFormDefault()
            history.push("/sale")
        }
        if(dataReturn?.error) alertPopup(dataReturn.error);
        if(dataReturn?.warning) alertPopup({message:dataReturn.warning,header:'Errore nei dati inseriti',buttons:['OK']});
    },[dataReturn])

    // intercetto lo stato di "loading" e faccio apparire il loader
    useEffect(() => {
        isLoading ? present():dismiss()
    },[isLoading])

    // intercetto l'errore del server e lo mostro all'utente.
    useEffect(() => {
        if(isError) alertPopup('Errore nella comunicazione con il server')
    },[isError])

    // all'avvio
    useEffect(() => {
        setFormDefault()
    },[])

    // valido i dati inseriti negli input
    const onChange = (e: any) => {

        // Setto i valori da mandare eventualmente con il submit
        setValues({ ...values, [e.target.name]: e.target.value});

        // Confronto il pattern per vedere se l'input presenta errori
        const errore = !e.target.value || e.target.value?.match(e.target.pattern) != null ? 'false':'true'; 
        
        // Cerco l'elemento con l'errore e lo setto su "errore"
        setInputs([...inputs.map((x:any) => {
            x.error = x.name == e.target.name ? errore:'false' 
            return x
        })])
    };

    // Disabilito il bottone quando necessario
    const statusPulsante = (dati:any) => {
        return dati.filter((x:any) => (x.error == 'true')).length > 0 || isLoading ? true:false
    }

    // Al click sul bottone
    const handleSubmit = (e: any) => {
        e.preventDefault();
        call(values);
    };

    return (
        <IonList>
            <form onSubmit={handleSubmit}>
                <IonListHeader>
                <IonLabel>Compila i campi per prenotare la {datiSala?.descBreve || 'Sala'}</IonLabel>
            </IonListHeader> 
                {inputs.map((input: any) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                    />
                ))}
                <IonItem>
                    <IonNote>Dopo la richiesta, se la sala è disponibile ti invieremo un link per il pagamento e l'inserimento dei dati di fatturazione. Se sei un convenzionato riceverai solo la conferma di prenotazione</IonNote>
                </IonItem>
                <IonButton className="ion-margin-top" expand="block" color="primary" type="submit" disabled={statusPulsante(inputs)}>Richiedi Prenotazione Sala</IonButton>
            </form>
        </IonList>
    );
};

export default FormPrenotazioneSala;