import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { personOutline, alarmOutline, listOutline, settingsOutline } from 'ionicons/icons';

const listaLink = [
    { 'nome': 'Ordini', 'icona': listOutline,'url': '/ordini' },    
    { 'nome': 'Prenotazioni', 'icona': alarmOutline, 'url': '/prenotazioni' },
    { 'nome': 'Impostazioni', 'icona': settingsOutline,'url': '/impostazioni' },
]

const UtenteButton: React.FC<{triggerId:string}> = (prop) => {

    return (
            <IonButton id={prop.triggerId}>
                <IonIcon slot="icon-only" icon={personOutline} />
                <IonPopover trigger={prop.triggerId} dismiss-on-select={true}>
                    <IonContent>
                        <IonList>
                            {
                                listaLink.map((g: any, index) => (
                                    <IonItem button={true} detail={false} key={index} onClick={() => (console.log('cliccato'))} lines={listaLink.length == index+1 ? 'none':'inset'} >
                                        <IonIcon slot="start" icon={g.icona}></IonIcon>
                                        <IonLabel>{g.nome}</IonLabel>
                                        {/* <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon> */}
                                    </IonItem>
                                ))
                            }
                        </IonList>
                    </IonContent>
                </IonPopover>
            </IonButton>
    )
};

export default UtenteButton