const categorie = [
    {
        "id": 0,
        "nome": "Dolci",
        "img": "https://bleisure.space/immagini/dolci.jpeg"
    },
    {
        "id": 1,
        "nome": "Fiori",
        "img": "https://bleisure.space/immagini/fiori.jpeg"
    },
    {
        "id": 2,
        "nome": "Lo Chef Propone",
        "img": "https://bleisure.space/immagini/chef-propone.jpeg"
    },
    {
        "id": 3,
        "nome": "Drink",
        "img": "https://bleisure.space/immagini/drink.jpeg"
    },
    {
        "id": 4,
        "nome": "Arredo Design",
        "img": "https://bleisure.space/immagini/arredo-design.jpeg"
    },
    {
        "id": 5,
        "nome": "Food Fashion",
        "img": "https://bleisure.space/immagini/ffashion.jpeg"
    }
]

const sottoCategorie = [
    {
        "categoria": "Dolci",
        "sottocategorie": ["Tutti"],
        "lista": [
            {
              "id": 1,
              "nome": "Tiramisù",
              "desc": "Dolce a base di savoiardi, mascarpone e caffè espresso",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "8.00"
            },
            {
              "id": 2,
              "nome": "Babà",
              "desc": "Classico dolce di Napoli con il suo gusto semplice abbinato ad un goccio di rhum ",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00"
            },
            {
              "id": 3,
              "nome": "Semifreddo",
              "desc": "Abbinamento della panna montata con la crema, con il cioccolato o con la frutta",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "7.50"
            },
            {
              "id": 4,
              "nome": "Pasticciotto",
              "desc": "Il pasticciotto è un tipico dolce della Puglia. È formato da pasta frolla con un ripieno di crema pasticcera.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "9.00"
            },
            {
              "id": 5,
              "nome": "Torta ai lamponi",
              "desc": "Torta creata con frolla, crema pasticcera e lamponi freschi",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "6.50",
            },
            {
              "id": 6,
              "nome": "Tonka",
              "desc": "Frolla con frangipane al pistacchio e mousse al cioccolato bianco aromatizzata alle fave di tonka",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "8.00",
            },
            {
              "id": 7,
              "nome": "Millefoglie",
              "desc": "Sfoglia con crema pasticcera alla vaniglia",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "7.50",
            },
            {
              "id": 8,
              "nome": "Sacher",
              "desc": "Pan di spagna al cacao con sciroppo alla vvaniglia e marmellata di albicocche",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00",
            },
            {
              "id": 9,
              "nome": "St.Honoré",
              "desc": "Sfoglia e pan di spagna accompagnati da panna montata e bignè ripieni di crema pasticcera",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "8.50",
            },
            {
              "id": 10,
              "nome": "Cioccolato e lamponi",
              "desc": "Doppia Frolla alla nocciola, ganache di cioccolato fondente e polpa di lampone.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "7.00",
            },
          ]
    },
    {
        "categoria": "Fiori",
        "sottocategorie": ["Tutti"],
        "lista": [
            {
              "id": 1,
              "nome": "Bouquet di Peonie",
              "desc": "Esplosione di petali belle e iconiche, centrotavola perfetto.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 2,
              "nome": "Flower Pot",
              "desc": "Una borsetta riempita da bellissimi fiori!",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "40.00",
            },
            {
              "id": 3,
              "nome": "Flowers Box",
              "desc": "Fiori in una cappelliera in velluto realizzata artigianalmente",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "60.00",
            },
            {
              "id": 4,
              "nome": "Secchiello in zinco",
              "desc": "Un dolce secchiello contenente rose e garofani dai colori leggeri e raffinati per stupire con una composizione d'eccezione.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 5,
              "nome": "Box Rose Fresche",
              "desc": "La box di rose fresche sono disponibili in diverse dimensioni e colori di fiore.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 6,
              "nome": "Bouquet di fiori di stagione",
              "desc": "Delicata composizione di fiori primaverili. Disponibile in più dimensioni.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "30.00",
            },
            {
              "id": 7,
              "nome": "Bonsai di Metasequoia",
              "desc": "Splendido bonsai di Metasequoia, un dono particolare e originale dedicare a una persona speciale.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "150.00",
            },
            {
              "id": 8,
              "nome": "Suit Case Flowers Box",
              "desc": "Romantica ed elegante Valigetta dal sapore Vintage",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "60.00",
            },
            {
              "id": 9,
              "nome": "Bouquet di Tulipani",
              "desc": "Uno splendido bouquet di tulipani dai colori accesi e vibranti",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "20.00",
            },
            {
              "id": 10,
              "nome": "Flower Basket Ortensie e lamponi",
              "desc": "Una nuova concezione di bouquet nel cono riciclato di cartone in una composizione originale!",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "75.00",
            },
          ]
    },
    {
        "categoria": "Lo Chef Propone",
        "sottocategorie": ["Tutti"],
        "lista": [
            {
              "id": 1,
              "nome": "Lasagna Gourmet",
              "desc": "Tradizione e innovazione partendo dalla ricetta originale alla riscoperta dei gusti di una volta",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "25.00",
            },
            {
              "id": 2,
              "nome": "Vellutata di Zucca e Castagne",
              "desc": "Cremosa vellutata di zucca dal sapore tipicamente autunnale arricchita dal gusto caratteristico della castagna",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "14.00",
            },
            {
              "id": 3,
              "nome": "Panzanella",
              "desc": "Un piatto di recupero che lo Chef onora producendo il pane apposta per questo prodotto co un mix di tecnica, gusto e freschezza, con pochissimi ingredienti.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00",
            },
            {
              "id": 4,
              "nome": "Scialatielli burro e alici",
              "desc": "Un dolce secchiello contenente rose e garofani dai colori leggeri e raffinati per stupire con una composizione d'eccezione.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 5,
              "nome": "Filet Mignon di Vitello",
              "desc": "Pregiato filet mignon di vitello, un protagonista dal gusto ricco, elegante e raffinato.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "22.00",
            },
            {
              "id": 6,
              "nome": "Boeuf Bourguignon",
              "desc": "Guancia di manzo brasata al vino di Borgogna con carote e cipolle.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "23.00",
            },
            {
              "id": 7,
              "nome": "Ardesia di Salmone affumicato",
              "desc": "Ardesia di salmone affumicato al legno di faggio accompagnato da toast di pain de mie e creme fraiche all’aneto.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "19.00",
            },
            {
              "id": 8,
              "nome": "Bonbon di bourguignon al Brie",
              "desc": "Polpette di brasato di manzo al vino di Borgogna, accompagnate da una salsa a base di Brie de Meaux.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "18.00",
            },
          ]
    },
    {
        "categoria": "Drink",
        "sottocategorie": ["Tutti", "Bollicine", "Bianchi e rosè", "Rossi da dessert", "Spirits & Cocktails", "Birre", "Soft Drinks"],
        "lista": [
            {
              "id": 1,
              "nome": "Moët & Chandon Impérial",
              "desc": "Champagne e spumante dai vitigni Pinot Noir, Pinot Meunier, Chardonnay.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "49.00",
              "categoria": ["Bollicine", "Bianchi e rosè"]
            },
            {
              "id": 2,
              "nome": "Ruinart Blanc de Blancs",
              "desc": "Champagne e spumante dai vitigni Chardonnay",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "95.00",
              "categoria": ["Rossi da dessert", "Bianchi e rosè"]
            },
            {
              "id": 3,
              "nome": "Dom Pérignon Vintage",
              "desc": "Champagne e spumante dai vitigni Pinot Noir, Chardonnay",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "218.00",
              "categoria": ["Rossi da dessert", "Bianchi e rosè"]
            },
            {
              "id": 4,
              "nome": "Krug Grande Cuvée",
              "desc": "Champagne e spumante dai vitigni Pinot Noir, Chardonnay e Meunier",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "220.00",
              "categoria": ["Bollicine", "Spirits & Cocktails"]
            },
            {
              "id": 5,
              "nome": "Vodka Belvedere",
              "desc": "Vodka polacca di Segale \"Dankowskie Gold Rye\"",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "48",
              "categoria": ["Bollicine", "Spirits & Cocktails"]
            },
            {
              "id": 6,
              "nome": "Cognac Hennessy",
              "desc": "Ottimo Cognac Francese",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "45.00",
              "categoria": ["Soft Drinks", "Rossi da dessert"]
            },
            {
              "id": 7,
              "nome": "Cesanese del Piglio Federici",
              "desc": "Decisi sentori di zagara e acacia con note agrumate. Al palato è un vino equilibrato e sapido, con un finale balsamico lungo e persistente",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "20.00",
              "categoria": ["Bianchi e rosè"]
            },
            {
              "id": 8,
              "nome": "Amaro Bianco Organico ",
              "desc": "Erbe, radici e alambicchi di rame alla ricerca di un'eccellenza capace di trasformare un distillato in un gran liquore",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "40.00",
              "categoria": ["Soft Drinks", "Rossi da dessert"]
            },
            {
              "id": 9,
              "nome": "Blues Grillo",
              "desc": "Vino bianco del 2018 di origine siciliana",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "15",
              "categoria": ["Bianchi e rosè"]
            },
            {
              "id": 10,
              "nome": "Dina Barbera D'Alba",
              "desc": "Rosso D.O.C. Piemontese dai vitigni di Barbera",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "15.00",
              "categoria": ["Bianchi e rosè", "Rossi da dessert"]
            },
          ]
    },
    {
        "categoria": "Arredo Design",
        "sottocategorie": ["Tutti"],
        "lista": [
            {
              "id": 1,
              "nome": "Candela profumata",
              "desc": "Candela della fragranza Azad kashmere, contenuta in vaso di vetro, completa di coperchio in noce canaletto.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "60.00",
            },
            {
              "id": 2,
              "nome": "Diffusore d'essenza",
              "desc": "Profumazione d'ambiente elegante e avvolgente",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "38.00",
            },
            {
              "id": 3,
              "nome": "Candele in cubi",
              "desc": "Candela lavorata a mano con tecnica a sabbia. Profumata con oli essenziali naturali dalla fragranza delicata mai invadente.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "15.00",
            },
            {
              "id": 4,
              "nome": "Ceramiche Terre di Scirrocco",
              "desc": "Creazioni uniche e pregiate artigianali",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "100.00",
            },
            {
              "id": 5,
              "nome": "Torta ai lamponi",
              "desc": "Torta creata con frolla, crema pasticcera e lamponi freschi",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "6.50",
            },
            {
              "id": 6,
              "nome": "Tavolo in cristallo",
              "desc": "Tavolo in cristallo adatto a cene di una certa importanza",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "200.00",
            },
            {
              "id": 7,
              "nome": "Sedie in cromo",
              "desc": "Moderne sedute in cromo dal design moderno e accattivante",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
          ]
    },
    {
        "categoria": "Food Fashion",
        "sottocategorie": ["Tutti"],
        "lista":  [
            {
              "id": 1,
              "nome": "Creme spalmabili a scelta",
              "desc": "Le gustosissime creme spalmabili di Cremilla disponibili nelle cinque varianti di gusto.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00",
            },
            {
              "id": 2,
              "nome": "Le carré",
              "desc": "Un misto di formaggi francesi di stagione, a base di differenti tipi di latte e diverse paste per giocare con i sensi.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00",
            },
            {
              "id": 3,
              "nome": "Pain aux Noix",
              "desc": "Pane lavorato con farina tradizionale, sale, acqua e lievito. Reso ancora più gustoso dall'aggiunta di noci.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "7.50",
            },
            {
              "id": 4,
              "nome": "Gift Box Gialla",
              "desc": "Gift Box in pregiata carta gialla elegantemente intagliata, contenente monoporzioni di gelatine alla frutta e alla liquirizia Wally & Whiz in differenti gusti, selezionati casualmente.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "27.00",
            },
            {
              "id": 5,
              "nome": "Confetture & Marmellate Pure Stagioni",
              "desc": "Il gusto della frutta 100% italiana e dall'iconico packaging le confetture di Pure Stagioni non hanno conservanti, coloranti e pectine!",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "10.00",
            },
            {
              "id": 6,
              "nome": "Gift Box \"Note di Dolcezza\"",
              "desc": "L'iconico taccuino Moleskine in tre diverse colorazioni in abbinamento al meglio del cioccolato pieno di dolcezza!",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 7,
              "nome": "Box Aperitivo Americano",
              "desc": "Una box completa di tutto l\"occorrente per un aperitivo perfetto: l\"Americano agrumi e zenzero di Opificio 77, le patatine gourmet \"Patatas Nana\" e due Tumbler",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "35",
            },
            {
              "id": 8,
              "nome": "Selvatiq - Red gin box",
              "desc": "elvatiq Gin rosso è realizzato con botaniche ottenute da un foraging consapevole e conservativo distillate singolarmente e raccolte a man",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "70.00",
            },
            {
              "id": 9,
              "nome": "Gift Box \"Ginger Passion\"",
              "desc": "Questa elegante gift box color cioccolato firmata Drogheria Europea è pensata appositamente per stupire gli amanti dello zenzero e contiene: Gimber BIO,",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "50.00",
            },
            {
              "id": 10,
              "nome": "Box Cocktail Negroni Bianco",
              "desc": "Il più famoso aperitivo al mondo, possiede anche una variazione sul tema decisamente unica e che varrebbe la pena scoprire.",
              "img": "https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg",
              "prezzo": "30.00",
            },
          ]
    }
]

const eventiFake = [
    {
        "id": 0,
        "nome": "Business Matching",
        "img": "https://bleisure.space/immagini/evento-1.jpeg"
    },
    {
        "id": 1,
        "nome": "Jazz",
        "img": "https://bleisure.space/immagini/evento-2.jpeg"
    },
    {
        "id": 2,
        "nome": "Comici",
        "img": "https://bleisure.space/immagini/evento-3.jpeg"
    },
    {
        "id": 3,
        "nome": "Gourmet Taste",
        "img": "https://bleisure.space/immagini/evento-4.jpeg"
    },
    {
        "id": 4,
        "nome": "DJ Set",
        "img": "https://bleisure.space/immagini/evento-5.jpeg"
    },
    {
        "id": 5,
        "nome": "Soul",
        "img": "https://bleisure.space/immagini/evento-6.jpeg"
    }, 
    {
        "id": 5,
        "nome": "Speciale",
        "img": "https://bleisure.space/immagini/evento-7.jpeg"
    }
]


export {categorie, sottoCategorie, eventiFake}