import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import FormPrenotazioneSala from "../components/FormPrenotaizioneSala";

const PrenotaSalaPage: React.FC = (props: any) => {

    return (
            <IonPage id="PrenotaSalaPage">
                <IonHeader >
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="../tab2" />
                        </IonButtons>                        
                        <IonTitle size="large">Prenota</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent  >
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">Prenotas</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <FormPrenotazioneSala datiSala={props.location.state} />
                </IonContent>
            </IonPage>

    );
}

export default PrenotaSalaPage;