import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRow, IonSegment, IonSegmentButton, IonSkeletonText, IonThumbnail, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import splitArray from '../utils/splitArray';

// const prodotto = {id:'',nome:'',desc:'',img:'https://podereilcasale.com/wp-content/uploads/2017/05/IlCasale_shop1396-1.jpg'};
// const lista: { id: number; nome: string; img: string; desc:string}[] = [];
// const creaArray = function () {
//     for (let index = 0; index < 10; index++) {
//         lista.push({ ...prodotto, id: index , nome:'Prodotto B'+index, desc:'Incredibile prodotto dalle grandi caratterisctiche'});
//     }
// }()




const AporterCategoria: React.FC<{ listaProdotti: [] }> = (prop) => {

    const [showModal, setShowModal] = useState(false);
    const [product, setProduct] = useState<any>();

    const handleShowProduct = (productSelected: any) => {
        setProduct(productSelected)
        setShowModal(true)
    }

    return (
        <>
            <IonList>
                {
                    prop.listaProdotti && prop.listaProdotti.length > 0 && prop.listaProdotti.map((prodotto: any, index: number) => (
                        <IonItem key={index} onClick={() => (handleShowProduct(prodotto))}>
                            <IonLabel>
                                {prodotto.nome}
                                <p>{prodotto.desc}</p>
                            </IonLabel>
                            <IonThumbnail slot="end">
                                <img src={prodotto.img} />
                            </IonThumbnail>
                        </IonItem>
                    ))
                }
            </IonList>
            <IonModal
                isOpen={showModal}
                swipeToClose={true}
                presentingElement={undefined}
                onDidDismiss={() => setShowModal(false)}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{product?.nome}</IonTitle>

                        <IonButtons slot='end'>
                            <IonButton onClick={() => setShowModal(false)}>Chiudi</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {product && <IonCard >
                        <IonCardContent>
                            <img src={product.img} style={{ width: '100%', objectFit: 'cover' }} />

                        </IonCardContent>
                        <IonCardHeader>
                            <IonCardTitle>
                                {product.nome}
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {product.desc}
                            </IonCardSubtitle>
                        </IonCardHeader>

                        <IonButton className='ion-margin' color="primary" size="default" fill="solid" expand='block'>Aggiungi al carrello</IonButton>
                        

                    </IonCard>}
                </IonContent>

            </IonModal>
        </>
    );
};

export default AporterCategoria;