import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { cartOutline } from 'ionicons/icons';

const Carrello: React.FC = () => {

    return (
            <IonButton>
                <IonIcon slot="icon-only" icon={cartOutline} />                
            </IonButton>
    )
};

export default Carrello