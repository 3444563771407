import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Grid, Keyboard, Pagination, Scrollbar, Zoom, Navigation } from 'swiper';


import '/node_modules/swiper/swiper.min.css';
import '/node_modules/swiper/modules/autoplay/autoplay.min.css';
import '/node_modules/swiper/modules/pagination/pagination.min.css';
import '@ionic/react/css/ionic-swiper.css';
import './Ristorante.css';
import { IonCard, IonItem, IonIcon, IonLabel, IonButton, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonGrid, IonCol, IonRow, IonFooter, IonToolbar, IonListHeader, IonThumbnail, IonList, IonSkeletonText, IonSegment, IonSegmentButton } from '@ionic/react';
import { costanti } from '../utils/costant';
import { arrowForwardOutline } from 'ionicons/icons';
import ImageSpecial from './imageSpecial';
import { useState } from 'react';


const IMMAGINI_RISTORANTE = costanti.IMMAGINI_RISTORANTE;
const MENU_RISTORANTE = costanti.MENU_RISTORANTE;
const PIATTO_IMG_DEFAULT = costanti.IMMAGINE_PIATTO_DEFAULT;

const Ristorante: React.FC = () => {

    const filtraPerOrario = (orario:string) => {
        let listaNuova:any = [];
        MENU_RISTORANTE.map((categoria)=>{
            let listaFiltrata = categoria.lista.filter((y)=>{ if(y.orario.indexOf(orario) !== -1) return y})
            if(listaFiltrata.length > 0){
                let nuovoObj = {tipo:categoria.tipo,lista:listaFiltrata}
                listaNuova.push(nuovoObj);
            }
        })

        return listaNuova;
    }

    const [orarioSelezionato,setOrarioSelezionato] = useState('pranzo')
    const [menuFiltrato,setMenuFiltrato] = useState(filtraPerOrario(orarioSelezionato))

    const handleCambioOrario = (orario:string) => {
        setOrarioSelezionato(orario)
        setMenuFiltrato(filtraPerOrario(orario))
    }
    

    return (
        <>
            <div>

                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={true}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    {IMMAGINI_RISTORANTE.map((i, index) => (
                        <SwiperSlide key={index} style={{ background: 'black' }}>
                            {/* <img src={i} /> */}
                            <ImageSpecial sorgente={i} />
                        </SwiperSlide>
                    ))}

                </Swiper>
                <IonList>
                    <IonItem>
                        <IonLabel>
                            <h1>Business Design Restaurant</h1>
                            <p>Business + Leisure Cafè nel cuore di Milano</p>
                        </IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton color="medium" expand="block" size="default" fill='outline'>Chiama</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton color="medium" expand="block" size="default" fill='outline' >Prenota</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                </IonList>

                <IonSegment onIonChange={e => { handleCambioOrario( e.detail.value || 'pranzo')}} color="medium" value={orarioSelezionato}>
                    <IonSegmentButton value="pranzo">
                        <IonLabel>Pranzo</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="cena">
                        <IonLabel>Cena</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonList>
                    {
                        menuFiltrato.map((tipi:any, index:number) => (
                            <div key={index}>
                                <IonListHeader >
                                    <IonLabel><h1>{tipi.tipo}</h1></IonLabel>
                                </IonListHeader>
                                {tipi.lista.map((piatto:any, indice:number) => (
                                    <IonItem key={indice} lines={tipi.lista.length == indice + 1 ? 'none' : 'inset'} >
                                        <IonThumbnail slot="start"  >
                                            <img src={piatto.img || PIATTO_IMG_DEFAULT} style={{ borderRadius: '5px' }} />
                                        </IonThumbnail>
                                        <IonLabel>
                                            {piatto.nome}
                                            <p>{piatto.testo}</p>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                                {/* <IonItem>
                                    <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon>
                                    <IonLabel>Altri {tipi.tipo} nel menù completo</IonLabel>
                                </IonItem> */}
                            </div>
                        ))
                    }

                </IonList>
            </div>
            <IonFooter collapse="fade" style={{ position: 'sticky', bottom: '-2px', background: 'white' }}>
                <IonToolbar>
                    <IonButton expand="block" color="primary" size="default" fill="solid">Prenota ORA</IonButton>
                </IonToolbar>               
            </IonFooter>
        </>
    );
};

export default Ristorante;