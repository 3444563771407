import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';

const Home: React.FC = () => {
    
    return (
        <IonGrid style={{paddingTop:'10%'}}>
            <IonRow className='ion-justify-content-center'>
                <IonCol className="ion-align-self-center ion-text-center" >
                <div className='ion-padding-top'>
                    <img src="https://bleisure.space/wp-content/uploads/2020/11/cropped-Logo-Bleisure-Negativo-1-e1605177760232.png" alt='Logo Bleisure'></img>
                </div>
                <h2 style={{marginTop:0,color:'#dadcdc'}}>Lusso à-porter</h2>
                </IonCol>                
            </IonRow>
            <IonRow>
                <IonCol size='10' offset='1'>
                        <IonButton className="ion-margin-top" color='dark' expand='block' size='large' fill='outline' routerLink='/ristorante' >
                            Ristorante
                        </IonButton>
                        <IonButton className="ion-margin-top" color='dark' expand='block' size='large' fill='outline' routerLink='/sale'>
                            Sale Meeting
                        </IonButton>
                        <IonButton className="ion-margin-top" color='dark' expand='block' size='large' fill='outline' routerLink='/eventi'>
                            Eventi
                        </IonButton>
                        <IonButton className="ion-margin-top" color='dark' expand='block' size='large' fill='outline' routerLink='/aporter' >
                            À-porter
                        </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default Home;